import React from 'react'
import { Link } from 'gatsby'

import { Blanket, BlanketContent } from '../../components/Blanket'
import { Container } from '../../components/Container'
import { SEO } from '../../components/Seo'

const NotFoundPage = () => (
  <Blanket dark={false} noPadding>
    <SEO title='404: Not found' />

    <BlanketContent>
      <Container>
        <h3>404 NOT FOUND</h3>
        <p>Questa pagina non esiste</p>

        <Link to={'/'}>Torna alla home</Link>
      </Container>
    </BlanketContent>
  </Blanket>
)

export default NotFoundPage
